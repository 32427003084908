import React from "react";
import Image from "../image";
const AboutUS = () => {
  return (
    <div className="container about-us-container py-5">
      <div className="row align-items-center">
        <div className="col-xs-12 col-md-6 ">
          <div className="">
            <h1 className="about-us-heading-row">About Us</h1>
          </div>
          <div className="">
            <p className="">
              We are manufacturer of All types of Safety Gloves, Safety Wears,
              Martial Arts, Boxing Wears, Weight Lifting Equipment, Sports Wears
              and MMA Wears. All these goods are produced in different styles
              and materials according to the customer’s requirements and
              instructions. For further information about products visits our
              online Catalogue.
              <br />
              <br />
              Our products are exported throughout the world Particularly
              Europe, UK and USA. For any further information about our company
              products please visit our website products catalogue or contact us
              through
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <Image src="contact-us.png"></Image>
        </div>
      </div>
    </div>
  );
};

export default AboutUS;
