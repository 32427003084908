import React from "react"
import { Layout, SEO } from "../components"
import AboutUS from "../components/about-us/about-us-main"

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About Us" description="Shop Biz Services" />
      <AboutUS />
    </Layout>
  )
}
export default AboutUs
